import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { graphql, PageProps } from 'gatsby';
import classnames from 'classnames';
import { compact } from 'lodash';
import { Clock, MapPin } from 'react-feather';

import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import SEO from '../../components/SEO/SEO';
import Breadcrumb from '../../components/Breadcrumb';
import Divider from '../../components/Divider';
import TileCard from '../../components/TileCard';
import HeroPost from '../../components/HeroPost';
import { StrapiInlineCtaComponent } from '../../components/StrapiComponents/StrapiInlineCtaComponent';
import { RichTextField } from '../../components/RichTextField/RichTextField';
import StrapiButton from '../../components/StrapiLink/StrapiButton';
import {
  SingleEventPostQuery,
  LinkComponentFragment,
  InlineCtaComponentFragment,
  Scalars,
} from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './events-post.module.scss';

type PageContextType = GlobalPageContext & {
  id: Scalars['ID'];
  fetchOtherEvents: boolean;
};

const EventPostPage: React.FC<PageProps<SingleEventPostQuery, PageContextType>> = ({ data, pageContext }) => {
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });
  const { event, events } = data.strapi;

  const intl = useIntl();

  const moreEvents = compact(event?.relatedEvents ?? events);

  if (!websiteLocale || !event) return <p>Not found</p>;

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={event.seo?.metaTitle ?? event.pageSettings?.title ?? event.title ?? ''}
        description={event.seo?.metaDescription ?? event.pageSettings?.metaDescription}
        avoidIndexing={event.pageSettings?.avoidIndexing ?? false}
        image={event.image?.url}
        canonicalURL={event.seo?.canonicalURL}
        keywords={event.seo?.keywords}
        metaSocial={event.seo?.metaSocial}
        structuredData={event.seo?.structuredData}
      />
      <div className="container">
        <Breadcrumb pageContext={pageContext} lastCrumb={event.title}></Breadcrumb>
        <HeroPost
          variant="event"
          title={event.title}
          categories={compact(event?.categories)}
          date={event.eventTime}
          imageSrc={event.image?.url}
        />
        <div className={classnames('text-2 black-2', styles.date)}>
          <div>
            <time className="is-flex mb-2">
              <Clock className="text-2 black-4 mr-3" />
              <FormattedDate
                value={event.eventTime}
                month="long"
                day="2-digit"
                weekday="long"
                hour="numeric"
                minute="numeric"
              />
            </time>

            <address className="is-flex">
              <MapPin className="text-2 black-4 mr-3" />
              {event.eventAddress}
            </address>
          </div>
          <StrapiButton type="primary" key={event.link?.title} {...(event.link as LinkComponentFragment)} />
        </div>
        <div className={styles.main}>
          <RichTextField className="pt-10 pb-5 black-2" content={event.content} />
        </div>
        {moreEvents.length ? (
          <div className="container">
            <Divider paddingTop="100px" marginBottom="20px">
              <FormattedMessage id="eventsDividerText" defaultMessage="More Events" />
            </Divider>
            <div className={styles.gridWrapper}>
              {compact(moreEvents).map(({ id, title, slug, image, categories, eventTime, eventAddress }) => (
                <TileCard
                  linkText={intl.formatMessage({ id: 'eventsCardText', defaultMessage: 'Learn more and RSVP' })}
                  slugBase={pageContext.pathPrefixes.events}
                  key={`event-${id}`}
                  variant="event"
                  title={title ?? 'N/A'}
                  slug={slug ?? ''}
                  image={image?.url}
                  categories={compact(categories)}
                  date={eventTime}
                  address={eventAddress ?? ''}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>

      {event.callToAction && <StrapiInlineCtaComponent {...(event.callToAction as InlineCtaComponentFragment)} />}
    </LayoutWrapper>
  );
};

export const query = graphql`
fragment RelatedEvent on Strapi_Event {
  title
  slug
  image {
    ...StrapiImageEntityResponse
  }
  categories {
    data {
      id
      attributes {
        name
      }
    }
  }
  eventTime: event_time
  eventAddress: event_address
}

fragment EventPost on Strapi_Event {
  title
  slug
  eventTime: event_time
  eventAddress: event_address
  content
  categories {
    data {
      id
      attributes {
        name
      }
    }
  }
  image {
    ...StrapiImageEntityResponse
  }
  embed_code
  link {
    ...LinkComponent
  }
  callToAction: call_to_action {
    ...InlineCTAComponent
  }
  pageSettings: page_settings {
    ...PageSettings
  }
  seo {
    ...SEOComponent
  }
}

query SingleEventPost($id: ID!, $fetchOtherEvents: Boolean!, $currentDate: Strapi_DateTime!) {
  strapi {
    events(
      filters: {event_time: {gte: $currentDate}, id: {ne: $id}}
      pagination: {limit: 2}
    ) @include(if: $fetchOtherEvents) {
      data {
        id
        attributes {
          ...RelatedEvent
        }
      }
    }
    event(id: $id) {
      data {
        id
        attributes {
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          ...EventPost
          relatedEvents: related_events @skip(if: $fetchOtherEvents) {
            data {
              id
              attributes {
                ...RelatedEvent
              }
            }
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(EventPostPage));
